<template>
  <div id="app">
    <!-- <div id="app" v-app-scroll style="height: 100vh;overflow-y: scroll;"> -->
    <div id="nav">
      <Home />
      <!-- <router-view  v-else></router-view> -->
    </div>
  </div>
</template>
<script>
import Home from "../src/views/Home.vue";
export default {
  components: { Home },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  // font-size:20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
}

#nav {
  width: 100%;
  background-color: #f8fafc;
  // a {
  //   // font-weight: bold;
  //   color: #2c3e50;

  //   &.router-link-exact-active {
  //     color: #42b983;
  //   }
  // }
}
</style>
